import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import lynaza from "../images/lynaza-logo-color.svg"
import cirrusImg from "../images/pr_monochromatic.svg"
import lawDNAImg from "../images/data_process.svg"
import icon1 from "../images/envelope-regular.svg"
import icon2 from "../images/tags-solid.svg"
import icon3 from "../images/vector-square-solid.svg"
import icon4 from "../images/object-group-regular.svg"
import "../css/indexPage.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Lynaza" />
      <div className="lynaza">
        <div className="header-wrap">
          <div className="content-wrap">
            <div className="title">
              AI 文件管家 / AI 自動標籤 / AI 自動書籤生成 / 人工智慧
            </div>
            <div className="subTitle">Email 與文件整合方案首選</div>
          </div>
        </div>
        <div className="slogan">
          <div className="txt">
            簡單的事重複做、重複的事 AI 做 <br />
            人工智慧科技、人人都是 AI 訓練師
          </div>
        </div>
        <div className="products">
          <div className="section">
            <div className="products-item-wrap">
              <div className="products-item">
                <div className="img-wrap">
                  <img src={cirrusImg} alt=""/>
                </div>
                <h1 className="title">Cirrus</h1>
                <div className="subTitle">email 整合小秘書</div>
                <div className="txt">
                  一個高效率與高規格的電子資訊安全服務。採內部稽核，達到事前風險評估並減少可能性威脅與損失。
                </div>
                <Link to="/cirrus">更多細節</Link>
              </div>
            </div>
            <div className="products-item-wrap">
            <div className="products-item">
              <div className="img-wrap">
                <img src={lawDNAImg} alt=""/>
              </div>
              <h1 className="title">Law DNA</h1>
              <div className="subTitle">整理文件小幫手</div>
              <div className="txt">
                為法律人量身打造的案件管理系統。不再需要印出紙本，人工貼標籤。卷證電子化閱卷的最佳選擇。
              </div>
              <Link to="/lawDNA">更多細節</Link>
            </div>
          </div>
          </div>
        </div>
        <div className="features">
          <div className="section">
            <div className="title">
              <img src={lynaza} alt="lynaza"/>
            </div>
            <div className="features-item-wrap">
              <div className="features-item">
                <div className="icon-wrap">
                  <img src={icon1} alt=""/>
                </div>
                <div className="title">
                  標籤整合
                </div>
                <div className="txt">
                  文件標籤是為了讓使用者達到敏捷查找與檢閱。立捷數據提供除標籤外，更提供複合索引達到更快速的查找結果。
                  <ul>
                    <li>條件式規則標籤設定</li>
                    <li>關鍵字標籤設定</li>
                    <li>人工智慧自動標籤</li>
                    <li>字元關聯性標籤設定</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="features-item-wrap">
              <div className="features-item">
                <div className="icon-wrap">
                  <img src={icon2} alt=""/>
                </div>
                <div className="title">
                  建立關聯
                </div>
                <div className="txt">
                  透過文件與文件內分析、建立人與人之間的關聯性。一對一、一對多，多對多關聯性是在內部文件治理中可得到 insight 的好幫手。
                </div>
              </div>
            </div>
            <div className="features-item-wrap">
              <div className="features-item">
                <div className="icon-wrap">
                  <img src={icon3} alt=""/>
                </div>
                <div className="title">
                  文件整理
                </div>
                <div className="txt">
                  企業文件盤點、數據盤點: 如何在數位化時代找到有價值的文件數據即是企業軟資產盤點的重要性。
                </div>
              </div>
            </div>
            <div className="features-item-wrap">
            <div className="features-item">
              <div className="icon-wrap">
                <img src={icon4} alt=""/>
              </div>
              <div className="title">
                郵件篩選 / 資料蒐集
              </div>
              <div className="txt">
                數位化時代，資料格式化上千種，立捷數據統整數據的第一且首要步驟是將企業資料統一格式化進行轉化。
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
  </Layout>
)

export default IndexPage
